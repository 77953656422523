:root {
  --background-color: #d0d0d0;
  --border-color: #b0b0b0;
  --text-color: black;
  --hover-background-color: #aaaaaa;
  --active-background-color: #c0c0c0;
  --header-background-color: #607d8b;
  --header-text-color: #ffffff;
  --container-background-color: #ffffff;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  background-color: var(--background-color);
}

.container {
  max-width: 1400px;
  min-width: 1000px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 15px;
  overflow: hidden;
  background-color: var(--container-background-color);
}

.Header {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: var(--header-background-color);
  color: var(--header-text-color);
  font-size: calc(10px + 4vmin);
  margin: 0;
}

.AnimationContainer {
  padding: 40px;
  box-sizing: border-box;
  margin: 0;
}

.button {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  color: var(--text-color);
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s ease;
  min-width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  margin: 3px;
}

.button:hover {
  color: var(--text-color);
  background-color: var(--hover-background-color);
}

.button:active {
  color: var(--text-color);
  background-color: var(--active-background-color);
}

.wholeBox {
  cursor: move;
  display: flex;
  padding: 20px;
  box-sizing: border-box;
  background-color: var(--container-background-color);
  border-radius: 10px;
}

.animation-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.controls-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
}

.drag-handle {
  cursor: move;
  color: var(--text-color);
  font-size: 20px;
  display: flex;
  justify-content: right;
  align-items: center;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}